<template>
  <div>
    <div class="projects">
      <template v-for="project in $store.state.current">
        <div v-if="type === 'all' || project.filter === type" :key="project.title">
          <router-link :to="'/works/single/' + project.url" class="noUnderline">
            <div class="projectTitle" v-html="$options.filters.truncate(project.title, 30)"></div>

            <div class="thumbAll projectThumb">
              <div class="projectThumbInner">
                <img :src="$store.state.cmsFiles + project.thumbnail.small" />
              </div>
            </div>
          </router-link>
        </div>
      </template>
    </div>

    <template v-if="hasArchive">
      <div class="title">Archive</div>
      <div class="projects">
        <template v-for="project in $store.state.archive">
          <div v-if="type === 'all' || project.filter === type" :key="project.title">
            <router-link :to="'/works/single/' + project.url" class="noUnderline">
              <div class="projectTitle" v-html="$options.filters.truncate(project.title, 30)"></div>

              <div class="thumbAll projectThumb">
                <div class="projectThumbInner">
                  <img :src="$store.state.cmsFiles + project.thumbnail.small" />
                </div>
              </div>
            </router-link>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
var VueTruncate = require('vue-truncate-filter');
Vue.use(VueTruncate);

export default {
  props: ['type'],

  computed: {
    hasArchive() {
      let projects = this.$store.state.archive.filter((project) => project.filter === this.type);
      return projects.length ? true : false;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.aboutTextsOuter {
  position: relative;
  padding-bottom: 62.5%;
}

.aboutTexts {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: $boxPadding;
  border: $border-square;
  display: flex;
  flex-direction: column;
}

.aboutTextsTop {
  flex: 1;
}

.aboutTexts p:last-child {
  margin-bottom: 0;
}

.projects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: $objectPadding;
  grid-row-gap: $projectSpacing;
}

@media screen and (max-width: $breakPoint1) {
  .projects {
    grid-template-columns: 1fr;
  }
}
</style>
