<template>
  <div class="projectSection" v-if="project.dates">
    <div class="projectSectionLeft">
      <div class="projectSectionTitle monthBackground dateSectionTitle">Previous</div>
    </div>

    <div class="projectSectionRight">
      <div class="projectBox">
        <project-date v-for="(item, index) in project.dates" :key="index + item.id + item.start + item.month" :item="item" class="dateLine"></project-date>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectDate from '@/components/A/Reusable/Date';

export default {
  components: {
    ProjectDate,
  },

  props: ['project'],
};
</script>
