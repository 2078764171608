<template>
  <div id="filters">
    <div class="filterBar" :class="'row' + ($store.state.types.length % 2)">
      <template v-for="type in $store.state.types">
        <router-link event="" @click.native="removeType(`/works/${type.url}`)" :to="`/works/${type.url}`" tag="div" :class="[$store.state.month]" class="filter">{{ type.title }}</router-link>
        <div class="filterBreak" aria-hidden="true"></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    removeType(e) {
      if (this.$route.path === e) {
        this.$router.push({ path: '/works/all' });
      } else {
        this.$router.push({ path: e });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

#filters a {
  text-decoration: none;
}

.filterBar {
  width: 100%;
  display: flex;
  padding: 0 $objectPadding 0 $mainPadding;
  flex-wrap: wrap;
}

.showFilters {
  display: none;
  padding: 0 $mainPadding;
}

.filter {
  user-select: none;
  position: relative;
  cursor: pointer;
  margin-right: $objectPadding;
  margin-bottom: $objectPadding;
  border: $border-square;
  border-radius: 10rem;
  padding: $boxPaddingFilters;
  display: flex;
  justify-content: center;
  transition: flex-grow 0.5s ease-in-out;
}

.filterBreak {
  width: 100%;
  display: none;
}

.row1 {
  .filter {
    flex: 1;
  }

  .filterBreak:nth-child(3n) {
    display: block;
  }
}

.row0 {
  .filter {
    flex: 2;
  }

  .filterBreak:nth-child(4n) {
    display: block;
  }
}

.filter.router-link-active {
  flex: 3;
}

.filter.break {
  display: none;
}

.filter::before {
  content: '\00d7';
  position: absolute;
  top: 0.1rem;
  right: 0.8rem;
  opacity: 0;
  transition: opacity 0.2s ease 0.1s;
}

.filter.router-link-active::before {
  opacity: 1;
}

@media screen and (max-width: $breakPoint1) {
  .filterBreak {
    display: block;
  }

  .activeFilters {
    margin-bottom: $mainPadding;
  }

  .activeFilters .filter {
    background-color: black;
    color: white;
  }

  .filterBar {
    flex-wrap: wrap;
  }

  .showFilters {
    display: block;
  }

  .filterBar.allFilters {
    padding-top: 1.5rem;
  }

  .filterBar.allFilters .filter.break {
    display: block;
    border: none;
    width: 100%;
    margin: 0;
    flex: auto;
  }

  .filterBar.allFilters .filter {
    flex: 1;
    margin-top: 0.5rem;
  }

  .filter::before {
    right: 0.8rem;
  }

  .filterBar.allFilters .filter.router-link-active {
    flex: 4;
  }

  .mobFilters {
    display: none;
  }
}
</style>
