import Vue from 'vue';
import app from '@/main.js';
import Router from 'vue-router';
import Calendar from '@/components/Calendar';
import Works from '@/components/Works';
import Single from '@/components/Single';
import Information from '@/components/Information';
import Auteur from '@/components/Auteur';

// Filter views
import All from '@/components/Works/All';

Vue.use(Router);

const workChildren = [
  {
    path: '',
    component: All,
    meta: {
      scroll: 'works',
    },
  },
];

const baseRoutes = [
  {
    path: '/',
    component: Calendar,
    name: 'calendar',
  },
  {
    path: '/works',
    redirect: '/works/all',
  },
  {
    path: '/works/single/:title',
    component: Single,
    name: 'single',
  },
  {
    path: '/works/:type',
    component: Works,
    children: workChildren,
  },

  {
    path: '/about',
    component: Information,
    name: 'info',
  },
  {
    path: '/about/auteur/:title',
    component: Auteur,
    name: 'auteur',
  },
  {
    path: '*',
    redirect: '/',
  },
];

export default new Router({
  mode: 'history',
  routes: baseRoutes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      // Scroll to anchor by returning the selector
      if (to.hash) {
        const myInterval = setInterval(() => {
          console.log(app.$store.state.information);
          if (app.$store.state.information) {
            let id = to.hash.replace('#', '');
            let top = document.getElementById(id).offsetTop;
            window.scrollTo(0, top);
            clearInterval(myInterval);
          }
        }, 100);
      } else {
        window.scrollTo(0, 0);
      }
    });
  },
});
