export default {
  methods: {
    sortWork(projects, types) {
      let projectsCurrent = [];
      let projectsArchived = [];

      projects.forEach(function (element) {
        if (element.status_id === 1 || element.status_id === 3) {
          projectsCurrent.push(element);
        }

        if (element.status_id === 2) {
          projectsArchived.push(element);
        }
      });

      this.$store.commit('types', types);
      this.$store.commit('projects', {
        projects: projects,
        current: projectsCurrent,
        archive: projectsArchived,
      });
    },
  },
};
