<template>
  <div>
    <div class="dateLine projectBox future" v-for="item in project.futureDates" :class="item.fullMonth">
      <div class="dateYear" :class="item.year ? '' : 'hideYear'">{{ item.year }}</div>
      <div class="dateRight">
        <div class="dateMonth">
          <template v-if="item.month == 'Jan' && item.start == '01'"></template>
          <div v-else class="month" v-html="item.month + '&nbsp;'"></div>
          <template v-if="item.month == 'Jan' && item.start == '01'"></template>
          <div class="flexday" v-else>
            <template v-for="day in item.days">
              <span>{{ day }}</span
              >&nbsp;
            </template>
          </div>
        </div>

        <div class="dateTitle">
          <template v-if="item.venue.website">
            <div class="eventName"><a :href="item.venue.event" v-html="item.venue.name" target="_blank"></a></div>
          </template>
          <template v-else>
            <div class="eventName" v-html="item.venue.name"></div>
          </template>
          <div class="eventAddress" v-html="item.venue.address" v-if="item.venue.address"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['project'],
};
</script>

<style lang="scss">
@import '@/assets/scss/dates.scss';
</style>
