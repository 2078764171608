<template>
  <div class="projectSection" v-if="project.website">
    <div class="projectSectionLeft">
      <div class="projectSectionTitle monthBackground">Website</div>
    </div>

    <div class="projectSectionRight">
      <div class="projectBox"><a target="_blank" :href="project.website" v-html="project.website"></a></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['project'],
};
</script>
